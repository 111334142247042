// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, ReactNode } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { initAuth } from "../../clients/firebase";
import UserService from "../../utils/Services/User";
import { IAuthContext } from "./index.d";
export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
  loading: true,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const auth = initAuth();
  const userService = new UserService();
  const [user, setUser] = useState<any | null>(
    JSON.parse(localStorage.getItem("user") || "null"),
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // Check if user data is in local storage
        const storedUser = JSON.parse(localStorage.getItem("user") as any);

        if (storedUser && storedUser.firebaseUid === firebaseUser.uid) {
          setUser(storedUser);
          setLoading(false);
        } else {
          // Fetch user data and store it
          userService
            .get_data("/firstSignInStatus")
            .then((res) => {
              setUser(res);
              localStorage.setItem("user", JSON.stringify(res));
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
        }
      } else {
        localStorage.removeItem("user");
        setUser(null);
        setLoading(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
