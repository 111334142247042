import "./App.css";

import { useRoutes } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import routes from "./router";

function App() {
  // Using the useRoutes hook to handle routing based on the defined routes
  const content = useRoutes(routes());

  return (
    <AuthProvider>
      <div className="App">{content}</div>
    </AuthProvider>
  );
}

export default App;
