import React, { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BsRocketTakeoffFill, BsTagsFill } from "react-icons/bs";
import { FaImages, FaRegSadCry, FaToolbox } from "react-icons/fa";
import { HiBuildingStorefront } from "react-icons/hi2";
import { ImBooks } from "react-icons/im";
import { IoMdSettings } from "react-icons/io";
import { MdSpaceDashboard } from "react-icons/md";
import { PiPlugsFill } from "react-icons/pi";
import { useLocation } from "react-router-dom";
import "./index.css";
const menus = [
  { title: "Create Product", href: "/", icon: FaToolbox },
  { title: "Generate Mockup", href: "/mockups", icon: MdSpaceDashboard },
  { title: "My Products", href: "/myProducts", icon: BsTagsFill },
  { title: "Store Builder", href: "/storeBuilder", icon: HiBuildingStorefront },
  {
    title: "Prompt Ideas",
    href: "/trending",
    icon: BsRocketTakeoffFill,
  },
  { title: "My Images", href: "/content", icon: FaImages },
  { title: "Integration", href: "/integration", icon: PiPlugsFill },
  { title: "Settings", href: "/profile", icon: IoMdSettings },
];
interface CustomSideElementInterface {
  title: string;
  href: string;
  isActive?: boolean;
  icon: any;
  isOpen: boolean;
}
const CustomSideElement = ({
  title,
  href,
  isActive = false,
  icon,
  isOpen,
}: CustomSideElementInterface) => {
  return (
    <a
      className={`flex w-full flex-col items-start justify-start gap-2.5
      p-2 text-main-blue-subMain  dark:text-white 
      ${
        isActive
          ? `custom-sidebar-shadow rounded-full  border-2
           border-white bg-main-pink-main text-white
           hover:text-main-blue-subMain dark:border-white dark:shadow-main-gray-300 dark:hover:text-main-blue-subMain`
          : "hover:text-main-pink-main dark:hover:text-main-pink-main"
      }`}
      href={href}
    >
      <div
        className={`inline-flex h-6 items-center justify-center  gap-4 md:justify-start ${
          isOpen ? "md:ml-2 md:w-52" : ""
        }`}
      >
        <div className={`${isActive ? "" : " dark:text-main-pink-main"}`}>
          {React.createElement(icon, { size: 25 })}
        </div>
        <div
          className={`text-16 hidden font-poppins font-semibold ${
            isOpen ? "md:block" : " "
          }`}
        >
          {title}
        </div>
      </div>
    </a>
  );
};
const CustomHelperActionElement = ({
  title,
  href,
  isActive = false,
  icon,
  isOpen,
}: CustomSideElementInterface) => {
  return (
    <a
      className={`flex w-full flex-row items-start justify-start gap-2.5 p-2 
    text-main-gray-300 hover:text-main-pink-main dark:text-main-gray-300 dark:hover:text-main-pink-main `}
      href={href}
    >
      <div className={`inline-flex h-6 items-center justify-start gap-4 `}>
        <div className="text-main-pink-main">
          {React.createElement(icon, { size: 20 })}
        </div>
        <div
          className={`hidden text-[12px] font-[700] ${
            isOpen ? "md:block" : " "
          } `}
        >
          {title}
        </div>
      </div>
      <div
        className={` hidden flex-grow items-center justify-end  ${
          isOpen ? "md:flex" : " "
        } `}
      >
        <div className={`text-main-pink-main ${isOpen ? "md:block" : " "} `}>
          {React.createElement(AiOutlineArrowRight, { size: 20 })}
        </div>
      </div>
    </a>
  );
};
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const currentPath = useLocation().pathname;
  return (
    <div
      className={`inline-flex h-full w-16 flex-col items-start justify-between gap-8 pb-8
     pt-2 text-black duration-500 ${isOpen ? "md:w-60" : " "}`}
    >
      <a
        id="logo"
        href="/"
        className=" flex items-center justify-center gap-3 self-stretch"
      >
        <img
          className="relative h-14 w-14 rounded-full"
          src="/assets/logo/Icon.png"
          alt="Launchify Logo"
        />
        {isOpen && <h3 className="animate-charcter">Launchify.ai</h3>}
      </a>
      <div
        id="top-section"
        style={{ maxHeight: "calc(100vh - 4rem)", overflowY: "auto" }}
        className="flex flex-col items-center justify-start self-stretch  overflow-x-hidden " // Add `justify-start` to place it at the start
      >
        <div className="flex flex-col gap-8">
          <div
            id="menus"
            className="m-auto flex w-[95%] flex-col items-start justify-start gap-3"
          >
            {menus.map(({ title, href, icon }: any, idx: number) => {
              const isActive = currentPath === href;
              return (
                <CustomSideElement
                  title={title}
                  href={href}
                  icon={icon}
                  key={idx}
                  isActive={isActive}
                  isOpen={isOpen}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div
        id="bottom-section"
        className="flex flex-col items-center justify-end gap-4 self-stretch"
      >
        {" "}
        <div className="relative hidden w-full md:block">
          <div
            id="openclose"
            className="absolute bottom-2 end-0 z-10 ml-auto mr-[-15px] cursor-pointer justify-end rounded-full
         bg-main-pink-main p-1 text-white dark:bg-main-pink-main dark:text-white"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen
              ? React.createElement(AiOutlineArrowLeft, { size: 30 })
              : React.createElement(AiOutlineArrowRight, { size: 30 })}
          </div>
        </div>
        <div
          className="flex flex-col items-start justify-start gap-2.5"
          id="helper-section"
        >
          <CustomHelperActionElement
            href="/contactUs"
            icon={FaRegSadCry}
            title="Help Me!"
            isOpen={isOpen}
          />
          <CustomHelperActionElement
            href="/platformLearning"
            icon={ImBooks}
            title="Platform Learning"
            isOpen={isOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
