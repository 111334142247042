import DefaultService from "../Default";
const COLLECTION = "user";

class UserService extends DefaultService {
  constructor() {
    super(COLLECTION);
  }
  async get_data(endPoint: string): Promise<any> {
    const res = await this.fetchJson<any>(endPoint, {
      method: "GET",
    });
    return res;
  }
  async post_data(endPoint: string, body: any = []) {
    const res = await this.fetchJson<any>(endPoint, {
      method: "POST",
      body: JSON.stringify(body),
    });
    return res;
  }
  async login(endPoint: string, body: any = []) {
    const res = await this.fetchJson<any>(endPoint, {
      method: "POST",
      body: JSON.stringify(body),
    });
    return res;
  }
}

export default UserService;
