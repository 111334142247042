import React, { useRef, useState } from "react";
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useDarkMode from "../../hooks/useDarkMode";
import useUserInfo from "../../hooks/useUserInfo";
import Dropdown, { DropdownInterface, DropdownItemInterface } from "./Dropdown";
import { FaUser, FaCog, FaSignOutAlt } from "react-icons/fa";
import useOutsideClick from "../../hooks/useOutsideClick";
import { initAuth } from "../../clients/firebase";

const Navbar: React.FC = () => {
  const { email, firstName, lastName, username } = useUserInfo();
  const navigate = useNavigate();
  const firebaseAuth = initAuth();
  const profileRef = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [colorTheme, setTheme] = useDarkMode(); // Get the current URL path using useLocation hook
  const dropdownOutsideClickRef = useOutsideClick(() => toggleDropdown());
  const toggleDarkMode = () => {
    setTheme((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const handleSignOut = () => {
    firebaseAuth.signOut();

    // Navigate to '/'
    navigate(0);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };
  const dropdownItems: DropdownItemInterface[] = [
    {
      label: "My Profile",
      onClick: () => {
        navigate("/profile");
        toggleDropdown();
      },
      icon: FaUser,
    },
    {
      label: "Settings",
      onClick: () => console.log("Settings clicked"),
      icon: FaCog,
    },
    {
      label: "Sign Out",
      onClick: handleSignOut,
      icon: FaSignOutAlt,
      split: true,
    },
  ];
  return (
    <nav
      className="w-max-max mx-auto flex h-16 flex-row items-center justify-between 
 p-2 duration-500 "
    >
      <div className="ml-auto inline-flex items-center gap-5 self-stretch ">
        <div className="relative inline-block text-left">
          <button
            className="focus:shadow-outline-blue inline-flex w-full justify-center rounded-md hover:text-main-pink-main dark:text-white dark:hover:text-main-pink-main"
            onClick={toggleDropdown}
          >
            <div className="hidden flex-col items-start justify-center md:inline-flex  ">
              <div className="w-max text-base font-bold ">{username}</div>
              <div className="w-max text-sm font-normal  dark:text-main-gray-300">
                {email}
              </div>
            </div>
          </button>
          {isDropdownVisible && (
            <Dropdown
              items={dropdownItems}
              dropdownOutsideClickRef={dropdownOutsideClickRef}
            />
          )}
        </div>
        <button
          className=" group flex items-center gap-3.5 text-main-pink-main dark:text-main-pink-main"
          onClick={toggleDarkMode}
        >
          {colorTheme === "dark"
            ? React.createElement(MdDarkMode, { size: 30 })
            : React.createElement(CiLight, { size: 30 })}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
